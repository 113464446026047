import Layout from "../components/layout"
import SEO from "../components/seo"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {
    faPhone,
    faExternalLinkAlt,
    faHeart,
    faLifeRing,
    faChevronLeft,
    faEye,
    faCheck
} from "@fortawesome/free-solid-svg-icons"

import React, {useState, useEffect} from "react";
import socketIOClient from "socket.io-client";

// const ENDPOINT = "https://overtime-socket.herokuapp.com";
const ENDPOINT = "https://overtime-socket.onrender.com";

export default class Page extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pin: 0,
            user: null,
            user_dummy: {"name": "Mia Müller", "employee": "Dominik"},
            payloads: [],
            payloads_dummy: [
                {
                    "pin": "2974",
                    "type": "url",
                    "value": {
                        "btn_text": "Zur Übersicht",
                        "title": "CarePlus",
                        "href": "https://ziegenhagel.com/services/careplus",
                        "banner_url": "https://www.oecd.org/media/oecdorg/directorates/environmentdirectorate/2020-2/leaves-5308582_1920.jpg",
                        "icon_url": "https://assets.ziegenhagel.com/img/careplus.png"
                    }
                },
                {
                    "pin": "2974",
                    "type": "url",
                    "value": {
                        "btn_text": "Download",
                        "title": "Teamviewer",
                        "href": "https://www.teamviewer.com/de/download/mac-os/?os=osx&language=de&country=DE",
                        "banner_url": "https://cdn.prod.www.spiegel.de/images/3993563a-9d9d-4733-87d9-7e131c1258e2_w1174_r1.33_fpx40.75_fpy49.95.jpg",
                        "icon_url": "https://static.teamviewer.com/resources/2018/07/teamviewer-logo-icon1.png"
                    }
                },
                {
                    "pin": "2974",
                    "type": "url",
                    "value": {
                        "btn_text": "Zur Support Seite",
                        "title": "Sicherung und Update",
                        "href": "https://ziegenhagel.com/support/sicherung-und-update",
                        "banner_url": "https://img.zeit.de/green/2021-09/thema-green-header-bild-neu/wide__1300x731",
                        "icon_url": "https://assets.ziegenhagel.com/img/logo_white.png"
                    }
                },
                {
                    "type": "quote", "value": {
                        "id": "227",
                        "status": "draft",
                        "title": "Jetpack",
                        "uid": "1",
                        "iss": "2022-03-03 19:16:06",
                        "pid": "103",
                        "fixeds": [
                            {
                                "id": "983",
                                "euros": "544",
                                "qid": "227",
                                "text": "Full WordPress setup and configuration (IDE & Online)"
                            },
                            {
                                "id": "984",
                                "euros": "466",
                                "qid": "227",
                                "text": "Interactive contact form"
                            },
                            {
                                "id": "989",
                                "euros": "0",
                                "qid": "227",
                                "text": "Up to two mockup optimization loops"
                            },
                            {
                                "id": "990",
                                "euros": "2144",
                                "qid": "227",
                                "text": "Content input of 5 pages incl. mockup in 3 languages (texts are provided by customer)"
                            },
                            {
                                "id": "991",
                                "euros": "544",
                                "qid": "227",
                                "text": "Use of novel compression methods and image formats for smoother operation and higher availability"
                            },
                            {
                                "id": "992",
                                "euros": "180",
                                "qid": "227",
                                "text": "Additional cache setup for load time optimization"
                            },
                            {
                                "id": "993",
                                "euros": "640",
                                "qid": "227",
                                "text": "Shopify integration"
                            }
                        ]
                    }
                }
                ,

                {
                    "pin": "2974",
                    "type": "hangup",
                    "value": {}
                },
            ]
        }
    }

    createPin() {

        console.log("layer3")
        const pin = Math.floor(Math.random() * 9000 + 1000)
        this.setState({pin})
        console.log("will socket pin")
        const socket = socketIOClient(ENDPOINT);

        socket.emit("PIN", pin)
        console.log("socket pin", pin)

        socket.on("user", user => {
            console.log("socket user", user)
            this.setState({user})
        });

        socket.on("payload", payload => {
            console.log("socket payload", payload)
            this.setState({
                payloads: [...this.state.payloads.filter(existing => !(
                    /* filter out when they are the same quote */
                    payload.type == "quote" && existing.type == "quote" && existing.value.id == payload.value.id
                )), payload]
            })
        });
    }

    componentDidMount() {

        console.log("touching endpoint");
        fetch(ENDPOINT).then().finally(function()  {
            console.log("layer 1");
            this.createPin();
        }.bind(this))

    }

    getPayloads() {
        return [...this.state.payloads].reverse()
    }

    render() {
        return (
            <Layout container={true} isDark={true} hasDarkColorScheme={true}>
                <div className="post-heading text-center" style={{paddingBottom: 50}}>
                    {
                        this.state.user == undefined ? <> <br/>
                            <h1>Telefon PIN</h1>
                            <p style={{maxWidth: 500, margin: "auto"}}>{this.state.pin > 0 ? "Ihre PIN lautet "+this.state.pin+"" : "Ihre PIN wird erstellt..."}</p>
                            <a href={"/support"} className={"btn mt-3 btn-primary-outline"}><FontAwesomeIcon
                                icon={faChevronLeft}/> Zurück zum normalen Support</a>
                            <br/> <br/> <br/> <br/> <br/>
                        </> : <>

                            <h1 className={"pullup"}
                                style={{marginTop: this.state.payloads.length == 0 ? 30 : -10}}>{this.state.payloads.filter(p => p.type == 'hangup').length > 0 ? 'Vielen Dank für Ihren Anruf!' : 'Hallo ' + this.state.user.name + '!'}</h1>
                            <p style={{maxWidth: 500, margin: "auto", marginBottom: 20}}>
                                {this.state.payloads.filter(p => p.type == 'hangup').length > 0 ?
                                    'Wir wünschen noch einen schönen Tag.' : 'Im Telefonat mit ' + this.state.user.employee + '.'}
                            </p>

                            {
                                this.getPayloads().length == 0 ?
                                    <h2 style={{margin: "40px auto", maxWidth: 400}}>
                                        Bitte lassen Sie diese Seite geöffnet, Sie erhalten gleich weitere
                                        Informationen.
                                    </h2>
                                    :
                                    this.getPayloads().map(({type, value}) => {
                                        if (type == 'url') {
                                            const {icon_url, btn_text, title, href, banner_url} = value
                                            return <div className={"pin-payload pin-" + type}
                                                        style={{backgroundImage: "linear-gradient(#0003,#000a),url(" + banner_url + ")"}}>
                                                <img src={icon_url}/>
                                                <h1>{title}</h1>
                                                <a href={href} className={"btn btn-light-outline"} target={"_blank"}>
                                                    <FontAwesomeIcon
                                                        icon={faExternalLinkAlt}></FontAwesomeIcon> {btn_text}
                                                </a>
                                            </div>
                                        } else if (type == 'hangup') {
                                            return <div className={"pin-payload pin-" + type}>
                                                <FontAwesomeIcon icon={faPhone}/>
                                            </div>
                                        } else if (type == 'quote') {

                                            const {fixeds, id} = value
                                            return <div className={"pin-payload pin-" + type}>
                                                <table className={"table table-hover"}>
                                                    {fixeds.map(fixed => <tr>
                                                        <td width={100}>{Math.round(fixed.euros)}€</td>
                                                        <td>{fixed.text}</td>
                                                    </tr>)}
                                                </table>
                                                <h2 className={"text-white"}>Die Summe für Angebot {id} liegt
                                                    bei {fixeds.reduce((partialSum, {euros}) => partialSum + parseInt('0' + euros), 0)}€
                                                    netto.</h2>
                                                <br/>
                                            </div>
                                        } else if (type == 'hours') {

                                            return <div className={"pin-payload pin-quote"}>
                                                <table className={"table table-hover"}>
                                                    {value.map(({mins,euros,text,status}) => <tr>
                                                        <td width={90}>{Math.round(mins)}min</td>
                                                        <td>{text}</td>
                                                    </tr>)}
                                                </table>
                                                <h2 className={"text-white"}>In Summe sind das {value.reduce((partialSum, {mins}) => partialSum + parseInt('0' + mins), 0)}min
                                                    bzw.  {value.reduce((partialSum, {euros}) => partialSum + parseInt('0' + euros), 0)}€
                                                    netto.</h2>
                                                <br/>
                                            </div>
                                        }

                                    })
                            }
                        </>
                    }
                </div>
            </Layout>
        )
    }
}

